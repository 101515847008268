import React from "react";
import "./CommonImg.css";
import imgCommon from "../../../assets/images/event.webp";
const CommonImg = () => {
  return (
    <div className="CommonImg">
      <img src={imgCommon} alt="updates" />
    </div>
  );
};

export default CommonImg;
