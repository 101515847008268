import React, { useContext, useEffect } from "react";
import EventCard from "./EventCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../../redux/slices/eventsSlice";
import Skelton from "../../ui/Skelton";
import { LanguageContext } from "../../../context/LanguageContext";

const EventList = () => {
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(fetchEvents(lang));
  }, [dispatch, lang]);
  return (
    <div className="EventList container">
      { loading && <Skelton />}

      {events?.data?.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  );
};

export default EventList;
