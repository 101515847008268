import React, { useContext, useState } from "react";
import "./Events.css";
import { LanguageContext } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const EventCard = ({ event }) => {
  const { title, description, when, where, link, image_path, id } = event;
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false); // للتحكم في حالة عرض النص بالكامل أو جزء منه
  const maxLength = 350; // الحد الأقصى لعدد الحروف

  // التحقق من إذا كان النص أطول من الحد الأقصى وإضافة 3 نقاط إذا لم يتم التوسيع
  const getShortDescription = () => {
    return description.length > maxLength && !expanded
      ? `${description.slice(0, maxLength)}...`
      : description;
  };

  return (
    <div className="EventCard">
      <motion.div
        initial={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
        className="EventCard_content"
      >
        <h2 className={lang === "ar" ? "EventCard_titleAr" : ""}>{title}</h2>
        <p>
          <div dangerouslySetInnerHTML={{ __html: getShortDescription() }} />
          {/* {getShortDescription()} */}
          {/* {description} */}
        </p>

        {/* {isOverflowing && (
          <button className="seeMore" onClick={() => setExpanded(!expanded)}>
            {expanded ? "See Less" : "See More"}
          </button>
        )} */}
        {description.length > maxLength && (
          <button className="seeMore" onClick={() => setExpanded(!expanded)}>
            {expanded ? t("See_Less") : t("See_More")}
          </button>
        )}

        <div className="placeTime">
          {where && (
            <div className="place">
              <h3>{t("where")}</h3>
              <p>{where}</p>
            </div>
          )}
          {when && (
            <div className="time">
              <h3>{t("when")}</h3>
              <p>{when}</p>
            </div>
          )}
        </div>
        {/* {link && ( */}
        <Link to={`/updates/${id}`} className="ExploreMore">
          {t("exploreMore")}
        </Link>
        {/*  )} */}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
        className="EventCard_img"
      >
        <img src={image_path} alt="Event" />
      </motion.div>
    </div>
  );
};

export default EventCard;
