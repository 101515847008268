import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTag = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
);

export default MetaTag;
