import React, { useContext, useEffect } from "react";
import CommonImg from "../../components/EventsComponents/CommonImg/CommonImg";
import EventList from "../../components/EventsComponents/OurEvents/EventList";
import { LanguageContext } from "../../context/LanguageContext";
import MetaTag from "../MetaTag";
import { motion } from "framer-motion";
import Details from "../../components/DetailComponents/Details";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventDetails } from "../../redux/slices/detailsSlice";
import LoadingPage from "../../LoadingPage";
const EventDetails = () => {
  const { id } = useParams();
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { eventDetails, loading, error } = useSelector(
    (state) => state.eventDetails
  );

  useEffect(() => {
    dispatch(fetchEventDetails({ lang, id }));
  }, [dispatch, lang]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <MetaTag
        title={eventDetails?.title}
        description={eventDetails?.description}
      />
      <CommonImg />
      <Details eventDetails={eventDetails} />
    </motion.div>
  );
};

export default EventDetails;
