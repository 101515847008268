import React, { createContext, useState } from "react";

const FaqContext = createContext();

const FaqProvider = ({ children }) => {
  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <FaqContext.Provider value={{ activeFaq, setActiveFaq }}>
      {children}
    </FaqContext.Provider>
  );
};

export { FaqProvider, FaqContext };
