import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Provider } from "react-redux";
import store from "./redux/store";
import { FaqProvider } from "./context/FaqContext";
import { BackgroundProvider } from "./context/backgroundContext";

import i18n from "./i18n"; // Import the i18n instance
import { LanguageProvider } from "./context/LanguageContext";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <ToastContainer />
    <HelmetProvider>
      <Provider store={store}>
        <FaqProvider>
          <BackgroundProvider>
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </BackgroundProvider>
        </FaqProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
