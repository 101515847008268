import React, { createContext, useState } from "react";
const BacgroundContext = createContext();

const BackgroundProvider = ({ children }) => {
  // const images = [img1, img2, img3, img4, img5, img6];
  const [activeImg, setActiveImg] = useState(null);

  return (
    <BacgroundContext.Provider value={{ activeImg, setActiveImg }}>
      {children}
    </BacgroundContext.Provider>
  );
};

export { BackgroundProvider, BacgroundContext };
