import { configureStore } from "@reduxjs/toolkit";
import HomePage, { bannersApi } from "./slices/HomePage";
import ProgramSlice from "./slices/ProgramSlice";
import eventsSlice from "./slices/eventsSlice";
import detailsSlice from "./slices/detailsSlice";
// import { bannersApi } from "./slices/bannersApi"; // استيراد الـ API

const store = configureStore({
  reducer: {
    banners: HomePage,
    programs: ProgramSlice,
    events: eventsSlice,
    eventDetails: detailsSlice,
    // أضف الـ reducer الخاص بـ RTK Query هنا
    [bannersApi.reducerPath]: bannersApi.reducer,
  },
  // أضف الـ middleware الخاص بـ RTK Query هنا
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bannersApi.middleware),
});

export default store;
