import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch program data
export const fetchPrograms = createAsyncThunk(
  "program/fetchPrograms",
  async (lang) => {
    const response = await axios.get(
      "https://euro-soccer-dahboard.eurosoccer.com.sg/api/programs",
      {
        headers: {
          lang: lang,
        },
      }
    );
    return response?.data?.data;
  }
);

export const postProgramData = createAsyncThunk(
  "program/postProgramData",
  async ({ body, lang }) => {
    const response = await axios.post(
      "https://euro-soccer-dahboard.eurosoccer.com.sg/api/program",
      body,
      {
        headers: {
          lang: lang,
        },
      }
    );
    return response.data.data;
  }
);

// Slice
const programSlice = createSlice({
  name: "program",
  initialState: {
    program: [],
    loading: false,
    error: null,

    data: [],
    loadingProgram: false,
    errorProgram: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrograms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        state.loading = false;
        state.program = action.payload;
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postProgramData.pending, (state) => {
        state.loadingProgram = true;
        state.error = null;
      })
      .addCase(postProgramData.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.data = action.payload;
      })
      .addCase(postProgramData.rejected, (state, action) => {
        state.loadingProgram = false;
        state.errorProgram = action.error.message;
      });
  },
});

// Export the reducer to be used in the store
export default programSlice.reducer;
