// detailsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk لجلب بيانات events
export const fetchEventDetails = createAsyncThunk(
  "events/fetchEventDetails",
  async ({ lang, id }) => {
    const response = await axios.get(
      `https://euro-soccer-dahboard.eurosoccer.com.sg/api/event/${id}`,
      {
        headers: {
          lang: lang,
        },
      }
    );
    // console.log(response?.data?.data);
    return response?.data?.data;
  }
);

// Slice
const detailsSlice = createSlice({
  name: "eventDetails",
  initialState: {
    eventDetails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.eventDetails = action.payload;
      })
      .addCase(fetchEventDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default detailsSlice.reducer;
